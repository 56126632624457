<template>
  <div class="container">
    <b-row>
      <b-col class="text-left">
        <h2 class="title-lab">Laboratório</h2>
      </b-col>
    </b-row>

    <formViewGeral />
  </div>
</template>

<script>
import formViewGeral from "@/components/laboratorio/formView/formViewGeral.vue";

export default {
  name: "viewLaboratorio",
  components: {
    formViewGeral,
  },
};
</script>


<style>
.title-lab {
  font-weight: 900;
  font-size: 2.5em;
  color: #cf0209;
  font-family: "Segoe_UI_Black_2";
  letter-spacing: 1px;
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-lab {
    font-size: 2em;
  }
}
</style>